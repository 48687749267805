@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap');

/*    General rules     */
::-webkit-scrollbar {
    width: 1rem;
    border-radius: 1rem;
}
div ::-webkit-scrollbar{
    width: .8rem;
}
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    margin: 5px;
}
::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.73);
    border-radius: .5rem;
    border: 1px solid rgba(200, 200, 200, .9);
}

::-webkit-input-placeholder {
    color: rgb(139, 139, 139);
}

::selection {
    background-color: var(--link);
    color: white;
}

body {
    color: var(--text);
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-position: bottom 0 center;
    background-size: contain, cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: var(--background-image), var(--background-gradient);
    cursor: default;
    display: flex;
    scroll-behavior: smooth;
    font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

span {
    font-size: 1rem;

}
#root, .myBody {
    /*height: 100vh; */
    overflow: hidden;
    width: 100%;
}

.myBody {
    grid-template-rows: 8vh 92vh;
}

*, *::before, *::after {
    /*font-size: clamp(.9rem, .7vw, 1rem);*/
    font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    word-break: keep-all;
    hyphens: auto;
    box-sizing: border-box;

}

ul {
    list-style-type: none;
    padding-inline-start: 0;
    padding-inline-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block: 0
}

h1, h2, h3, h4 {
    font-size: 1.3rem;
    opacity: .7;
    font-weight: 800;
    margin: .4rem;
}

h3 {
    font-size: 1.1rem;
}

/*Récurrences regroupées*/
.flex {
    display: flex;
}

.grid {
    display: grid !important;
}

.br20 {
    border-radius: 2rem;
}

.br10 {
    border-radius: 1rem;
}

.br05 {
    border-radius: .5rem;
}

.br02 {
    border-radius: .2rem;
}

.bord1 {
    border: solid 1px var(--div-border);
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: .5rem;
    padding: .2rem;
}

.sticky {
    position: sticky;
}

.fs1 {
    font-size: 1rem;
}
.fs12{
    font-size: 1.2rem;
}

.of-auto{
    overflow: auto;
}

.justLeft{
    justify-content: left !important;
}
.talignLeft{
    text-align: left;
}

.al-center{
    align-items: center;
}
input {
    padding: 0.3rem 0.1rem;
    border: 1px solid rgba(72, 72, 72, 0.64);
    text-align: center;
    border-radius: 0.3rem;
    height: 1.6rem;
    width: 90%;
    margin: .2rem;
}
input.large {
    padding: .2rem;
    font-size: 1.2rem;
    height: 38px;
}

img {
    max-width: 100%;
}

.tabcell > input {
    width: 6rem;
}

textarea.large {
    padding: .2rem;
    font-size: 1rem;
    height: unset;
    background-color: rgba(255, 255, 255, 0.8);
    resize: vertical;
}

input[type=checkbox] {
    width: 1.5rem;
}
input[type=date] {
    margin: 0;
    text-align: center;
}
input[type=file] {
    display: none;
}

select {
    background-color: white;
    display: inline-block;
    border-radius: 8px;
    border: solid 1px hsl(0, 0%, 80%);
    outline: none;
    appearance: none;
    padding: 0.5em 3.5em 0.5em 1em;
    line-height: 1.5rem;
    background-image: linear-gradient(45deg, transparent 50%, rgb(128, 128, 128) 50%),
    linear-gradient(135deg, rgb(128, 128, 128) 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
}
select:focus {

}
select.large{
     /*height: 3rem;*/
     text-align: center;
 }

.elmt-link:hover {
    font-weight: 700;
    color: var(--link);
    opacity: .8;
    transition: all 0.2s ease-in-out;
    cursor: pointer
}

a, .as-link {
    font-weight: 600;
    color: var(--link);
    text-decoration: underline rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    cursor: pointer

}
a.alert, .as-link.alert {
    color: red;
    text-decoration: underline;
}
a:hover, .as-link:hover {
    cursor: pointer;
    opacity: .8;
    text-decoration: underline var(--link);
    transition: all 0.2s ease-in-out;
}

div .only-for-small-screen {
    display: none;
}

.bg-wh {
    background: white;
}

.zi5{
 z-index: 5;
}
.h30vh{
    min-height: 30vh;
}
.h10vh{
    height: 10vh;
}
.h20vh{
    height: 20vh;
}
.h55vh{
    height: 55vh;
}
.h70vh{
    min-height: 70vh;
}
.h2r{
    min-height: 2rem;
}
.wmax {
    min-width: max-content;
}
.h100 {
    height: 100%;
}
.w100 {
    width: 100% !important;
}.w65 {
    width: 65%;
}
.w50 {
    width: 50% !important;
}
.w25 {
    width: 25%;
}
.w2 {
    width: 2rem !important;
}

.w70vw{
    width: 70vw;
}


.widfit {
    width: fit-content !important;
}

.expandable-div {
    position: relative;
    transition: all ease-in-out;
    text-align: -webkit-center;
}
.expandable-div .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.expandable-div.expanded .content {
    max-height: 50vh; /* Choisissez une valeur qui sera plus grande que la hauteur de votre contenu */
    transition: max-height 0.4s ease-in-out;
    overflow: auto;
}

.fileUploader {
    content: url("../images/add-new-doc.png");
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0.2rem;
    transform: scale(0.8);
    cursor: pointer;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.fileUploader:active {
    box-shadow: rgba(0, 0, 0, 0.31) 0 1px 5px;
    transform: scale(0.77);
    opacity: 0.9;
}
.fileUploader:hover {
    opacity: 0.8;
}

.react-responsive-modal-root:not(.transparent) {
    backdrop-filter: blur(5px);
}
.react-responsive-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    vertical-align: middle;
}
.react-responsive-modal-modal {
    display: grid;
    grid-template-rows: auto 1fr;
    min-width: 25vw;
    width: auto;
    margin: .5rem;
    max-height: 95vh;
    max-width: 98vw;
    border-radius: 20px;
    border: solid 2px var(--modal-border-color);
    background-color: var(--modal-background);
    transition: all ease 1s;
    backdrop-filter: blur(5px);
    padding: 8px;
}
.react-responsive-modal-modal > *:not(.react-responsive-modal-closeButton) {
   /* max-width: 98%; */
    margin: auto;
}
.react-responsive-modal-modal > :nth-child(3) {
    margin-top: -1.8rem;
}

.react-responsive-modal-modal.onTheRight {
    position: absolute;
    right: 1.5rem;
    max-width: 50%;
}
.react-responsive-modal-modal.no-scroll {
    overflow-y: hidden;
}

.MuiButtonBase-root.Mui-disabled {
    opacity: .3;
}

.react-responsive-modal-closeButton:active {
    opacity: .5
}
.react-responsive-modal-closeButton:hover {
    background: rgba(164, 0, 0, 0.5);
    border: 1px rgba(255, 0, 0, 0.3) solid;
    transition: .2s ease all;

}
.react-responsive-modal-closeButton {
    z-index: 1000;
    position: sticky;
    grid-row: 1;
    justify-self: end;
    top: 0;
    right: 0;
    width: fit-content;
    border-radius: 1rem;
    background: rgba(255, 255, 255, .3);
    border: 1px rgba(0, 0, 0, 0.49) solid;
}

.MuiIconButton-root.action-btn {
    font-family: Nunito, serif;
    padding: 0.3rem 2rem;
    border-radius: 2rem;
    color: whitesmoke;
    transition: all 0.4s ease-in-out;
    max-width: 100%;
}
.MuiIconButton-root.action-btn.sticky-bottom {
    position: sticky;
    bottom: 4rem;
    float: right;
}

.validation {
    background: var(--validation-button) !important;
}
.neutral {
    background: #194ac7 !important;
}
.cancel {
    background: var(--cancel-button) !important;
}
.MuiIconButton-root.action-btn:active {
    animation: pulse-white 0.5s ease;
}
.MuiIconButton-root.action-btn.save:before {
    transform: scale(0.7);
    content: url("../images/save-icon.png");
}
.MuiIconButton-root.action-btn.refresh:before {
    transform: scale(0.7);
    content: url("../images/refresh-icon.png");
}
.MuiIconButton-root.action-btn:hover {
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.4);
    text-shadow: rgba(0, 0, 0, 0.7) 0 0 3px;
    filter: brightness(1.8);
}
.MuiIconButton-root.action-btn a {
    text-decoration: none;
    color: unset;
}
.MuiIconButton-root.action-btn.selected {
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.4);
    text-shadow: rgba(0, 0, 0, 0.7) 0 0 3px;
    border: 1px solid whitesmoke;
    filter: brightness(1.8);
}

.header-section {
    padding: 1rem 1rem 1rem .5rem;
    background-color: var(--modal-background);
    border: 2px solid var(--modal-border-color);
}

.primary-section {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    padding: 1rem;
    gap: .5rem;
    border: 1.5px solid var(--div-border);
    background: #00000010;
}

.secondary-section {
    width: 100%;
    border: 1px solid #EEE;
    padding: .5rem;
    border-radius: .7rem;
    background-color: rgba(255, 255, 255, .15);
    box-shadow: inset 0 10px 10px 10px rgba(0, 0, 0, 0.02), 0 5px 10px rgba(0, 0, 0, .075);
    backdrop-filter: blur(10px);
    display: flex;
    gap: .2rem;
    flex-wrap: wrap;
    justify-content: normal;
}

.tertiary-section{
    border: 1px solid var(--link);
    width: 100%;
    padding: .4rem;
    border-radius: .3rem;
    background-color: rgba(255, 255, 255, .3);
    display: flex;
    gap: .1rem;
    flex-wrap: wrap;
    justify-content: normal;
}

.flex-column > span.alert {
    background-color: rgba(255, 0, 0, 0.7);
    animation: wobble 2s ease infinite;
}

.flex-column > span {
    border: 1px solid white;
    border-radius: .5rem;
    padding: .4rem;
    background-color: rgba(255, 255, 255, 0.7);
    min-width: 100%;
    font-weight: bold;
}

.flex-column {
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
    width: auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
}
.flex-row.auto-line {
    flex-wrap: wrap;
    gap: .5rem;
}
.flex-row.auto-line>span {
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding: .4rem;
    border-radius: .5rem;
    gap: .5rem;
    margin: .2rem;
    height: 2.5rem;
}

.alignSelfend {
    align-self: end;
}

.tr-corner {
    position: relative;
    top: 0;
    right: -8rem;
}
.br-corner {
    position: absolute !important;
    bottom: 1%;
    right: .5%;
}
.tl-corner {
    position: absolute !important;
    top: 1%;
    left: .5%;
}
.bl-corner {
    position: absolute !important;
    bottom: 15%;
    left: .5%;
}

.extend-button {
    content: '>';
    background-color: rgba(255, 255, 255, 0.32);
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.1rem;
    font-size: 1.5rem;
    transition: all ease-in-out .3s;
    margin: auto;
    z-index: 5;
}

.extend-button.is-folded {
    transform: rotate(90deg);
}
.extend-button.is-expanded {
    transform: rotate(-90deg);
}

/*     Navbar rules     */
.navbar {
    grid-template-columns: 2fr 10fr;
    grid-template-rows: 100%;
    z-index: 2;
}

.burger-btn {
    display: none;
}

.navbar .logo {
    transition: all ease 0.5s;
    text-align: end;
    margin-right: -1rem;
}

.navbar .logo img {
    height: 100%;
    transition: all ease 0.3s;
}
.navbar img:hover {
    transform: scale(1.3);
    opacity: .7;
    filter: brightness(1.2);
}
.navbar ul {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background: var(--nav-bar);
    display: flex;
    justify-content: end;
    gap: 1rem;
    align-items: center;
    padding-right: 2rem;
}
.navbar ul img {
    height: 41px;
    object-fit: none;
    object-position: bottom;
}
.navbar ul img:active {
    transform: scale(0.95);
    transition: 0.2s ease-in-out;
}
.navbar .profile {
    height: 41px;
    padding: 0 0.7rem;
    font-weight: 500;
    text-transform: capitalize;
    transition: 0.2s all ease-in-out;
    color: rgba(255, 255, 255, 0.6);
    gap: 0;
    line-height: 0;
}
.navbar .profile:after {
    content: url("../images/signout.png");
    padding: 0 0 0 0.6rem;
}
.navbar .profile:hover {
    background-color: rgba(255, 255, 255, 0.4);
    color: rgb(0, 0, 0);
    transition: 0.2s all ease-in-out;
    cursor: pointer;
}
.navbar .profile:active {
    transform: scale(0.95);
    cursor: pointer;
    content: "Se déconnecter";
}
.navbar .notificationNumber {
    animation: wobble 2s ease infinite;
    background-color: rgba(255, 0, 0, .7);
    bottom: -0.5rem;
    color: #f5f5f5;
    font-size: 12px;
    height: 2rem;
    line-height: 1.5rem;
    position: absolute;
    border-radius: 50%;
}

/*     Login rules     */
.login-form {
    max-width: 700px;
    min-height: 300px;
    height: 50vh;
    justify-self: center;
    padding: 1rem;
    border: 2px solid rgba(0, 0, 0, .25);
    -webkit-backdrop-filter: blur(2px) brightness(109%);
    background-color: rgba(255, 255, 255, 0.48);
    backdrop-filter: blur(2px) brightness(109%);
    box-shadow: 0 19px 35px rgba(0, 0, 0, .2);
}

.company-creation {
    max-width: 60vw;
    place-self: center;
}

.company-creation .zipTown {
    width: 90%
}

.logo-to-go {
    max-height: 5rem
}

.company-creation .townInput {
    width: 30%;
}

.criteria-bar {
    position: absolute;
    top: 50%;
    transform: scale(.8) translate(-60%, -50%);
    background-color: rgba(256, 256, 256, .1);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.2);
    border-radius: 0 5rem 5rem 0;
    opacity: .7;
    transition: all 0.3s ease-in-out .5s;
    z-index: 5;
    left: -.2rem;
}
.criteria-bar .margin-content {
    display: flex;
    flex-direction: column;
}

.criteria-bar:hover {
    opacity: 1;
    transform: scale(.8) translate(-20%, -50%);
    transition: all .05s ease-in-out;
}
.criteria-bar a .add-map button:disabled {
    opacity: 0.3;
    filter: grayscale(100%);
}

/*     Table rules --- column selector     */
.intervSearcher {
    width: 10rem;
    background-color: #0a0a0a;
}

.criteria-list-dropdown {
    border: 1px solid var(--interv-thead);
    position: absolute;
    left: 0;
    width: 3.5rem;
    z-index: 30;
}

.criteria-list li:hover {
    background-color: rgba(256, 256, 256, .5);
}

ul.criteria-list {
    transition: opacity 0.5s ease, height 0s ease .7s, width 0s ease .7s;
}


.activeSearch {
    overflow: auto;
    position: fixed;
    z-index: 1;
    top: 5vh;
    opacity: .9;
    right: 0;
    border: 5px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: opacity 0.7s ease, width 0s ease 1s;
}

.activeSearch.hidden {
    visibility: hidden;
}

.activeSearch .filter-bar .criteria {
    max-width: 4rem;
}

.criteria-list-dropdown ul.criteria-list {
    z-index: 3;
    display: inline-flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    overflow: auto;
    opacity: 0;
    height: 0;
    width: 0;
    transition: opacity .5s ease, height .2s ease, width .5s ease;
}
.criteria-list-dropdown.visible ul.criteria-list {
    opacity: .9;
    width: 15rem;
    left: 6rem;
    height: 80vh;
    transition: opacity 0.3s ease, height .5s ease, width 0s ease;
}
.criteria-list-dropdown ul.criteria-list li .criteria {
    display: flex;
    align-items: center;
    padding: 0.3rem;
    border-radius: 0.3rem;
    background-color: white;
}

/*     Table rules     */
.page {
    z-index: 2;
    height: 100%;
    padding-bottom: 1rem;
    justify-content: end;
    margin-top: 1rem;
}

.filter-bar {
    backdrop-filter: blur(5px);
    margin: .5rem 0;
    position: sticky;
    z-index: 8;
    top: 0;
    flex-direction: row;
}

.pager {
    gap: 0;
}

.x-scroll {
    overflow-x: auto;
}
.table, .table .scrollable {
    min-height: 10vh;
    flex-direction: column;
    align-items: end;
    height: 100%;
    float: right;
    position: relative;
}
.table .scrollable {
    overflow-x: hidden;
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}
.scrollable.flex-column {
    justify-content: start;
}

.table-element-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: 900;
}

.table-row {
    grid-auto-columns: minmax(3rem, 1fr);
    grid-auto-flow: column;
    width: 97%;
    align-items: center;
    gap: .2rem;
    padding: .3rem 0 .3rem 0;
}

.table-row.header>* {
    z-index: 20;
}
.table-row.header {
    z-index: 20;
    position: sticky;
    top: 0;
}

.filter-group {
    position: relative;
    transition: all ease-in-out 0.4s;
    width: webkit-fill-available;
}
.filter-group:hover {
    border-radius: 0.5rem;
    transition: all ease-in-out 0.2s;
    background-color: rgba(255, 255, 255, 0.1);
}

.filter-input {
    text-align: center;
    background-color: var(--interv-thead);
    box-shadow: inset 0 3px 3px #00000035;
    font-weight: 600;
    opacity: .9;
    color: #00000090;
    transition: all 1s ease;
    height: 2.7rem;
    margin: 0;
    padding: 0;
    border: none;
}
.filter-input::placeholder {
    color: transparent;
    user-select: none;
}
.filter-input:focus {
    box-shadow: none;
    outline: none;
    border-color: #cccccc;
}

.filter-label::before {
    content: url("../images/label-search.png");
}

.filter-label {
    color: whitesmoke;
    display: block;
    user-select: none;
    transition: all .3s ease-in-out;
    cursor: text;
    padding: .01rem;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    font-size: .7rem;
}

.filter-label:hover {
    transform: translateY(-.3rem);
    font-size: .9rem;
    padding: .5rem;
}

.filter-input:focus + .filter-label,
.filter-input:not(:placeholder-shown) + .filter-label {
    background-color: var(--interv-thead);
    transform: translateY(-2.2rem);
}

.label {
    color: #00000090;
    font-size: .7rem;
}
.label2 {
    color: var(--link);
    font-size: .9rem;
    background-color: rgba(255, 255, 255, 0.76);
    margin-top: -.5rem;
    width: max-content;
}

.table-row:nth-child(even) {
    background-color: var(--interv-bg-even);
}
.table-row:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    background-size: 200% 200%;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 35px;
    text-shadow: var(--highlighted-text-shadow);
    transition: all 0.2s ease-in-out;
}
.table-row:hover .cell {
    background-color: var(--td-cell-hover);
}
.table-row.glow {
    background: var(--glow);
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.4) 0 1px 30px;
    transition: all ease-in-out 0.2s;
}
.table-row.prioritize {
    background: linear-gradient(to right, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.2));
    background-size: 200%;
    animation: infinite 2s ease-in-out prioritize;
}

.cell {
    border-radius: 0.3rem;
    text-align: center;
    cursor: default;
    table-layout: auto;
    font-weight: 600;
    max-height: 5rem;
    hyphens: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 1px rgba(255, 255, 255, 0.3) solid;
    flex-wrap: wrap;
    text-wrap: balance;
    letter-spacing: -.04em;
    position: relative;
}
.cell:hover:not(tr .cell:first-child) {
    transition: all 0.1s ease-in-out;
    hyphens: auto;
    overflow: visible;
    letter-spacing: 0;
}
.cell a.id:hover {
    text-decoration: underline 0.1em;
    transition: text-decoration .3s;
    opacity: .8;
}
.cell a.id::before {
    content: '▶';
    opacity: .5;
}

.cell.status {
    flex-direction: column;
    font-size: small;
}
.cell.type {
    flex-direction: column;
    font-size: small;
}

.cell.address {
    font-size: small;
}

.cell.supply {
    flex-direction: column;
    font-size: small;
   display: flex;
}

.cell.supply, .cell.secteur, .cell.team, .cell.intervention, .cell.estimateDuration, .cell.estimate, .cell.bill, .cell.interactions {
    cursor: pointer;
    border: 1px solid white;
}

.cell::-webkit-scrollbar:hover {
    width: .1rem;
    height: 1rem;
}
.cell::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}
.cell::-webkit-scrollbar {
    width: .7rem;
    height: .5rem;
}
.cell span.alert {
    width: 0.5rem;
    min-width: auto;
    background: red;
    top: 63%;
}

/*    gridCard rules    */

.grid-card-scrollable .filter-bar {
    width: 99%;
    flex-wrap: wrap;
    gap: 0;
    z-index: 5;
    min-height: 3rem;
    align-items: center;
    justify-content: center;
}

.dashboard-switcher > *:hover {
    opacity: .5;
}
.dashboard-switcher {
    cursor: pointer;
}

.popup-filter {
    left: 0;
    justify-self: right;
    top: 0;
    width: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.filter-bar .criteria-list {
    transform: translateX(-15%);
    gap: .2rem !important;
}
.filter-bar .criteria {
    background: var(--link);
    color: whitesmoke;
    padding: .5rem;
    gap: 0;
    position: relative;
}
.filter-bar .criteria:before {
    content: attr(title);
    position: absolute;
    top: .2rem;
    left: 1rem;
}
.filter-bar .criteria input:active {
    border: none;
}
.filter-bar .criteria input {
    background: none;
    border: none;
    border-bottom: rgba(255, 255, 255, 0.2) .5px solid;
}
.filter-bar .criteria input::placeholder {
    color: whitesmoke;
}

.list-page {
    margin-left: 3.5rem;
}
.list-page .table .scrollable {
    overflow: auto;
    height: 82vh;
    background-color: rgba(255, 255, 255, 0.49);
}

.grid-card-scrollable {
    overflow: auto;
    height: 100vh;
    padding-bottom: 5rem;
}
.grid-card {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 7px;
}

.card-section {
    border: 2px solid rgba(255, 255, 255, 0.5);
    grid-template-areas: "sb sb sb sb sb sb"
                         "i t t t s s"
                         "si si si si si si";
    grid-gap: 4px;
    align-items: stretch;
    justify-items: center;
    font-weight: bold;
    /*max-height: 200px;*/
    justify-content: space-around;
}
.card-section:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 5px 5px;
    transition: all ease .3s;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
}
.card-section * {
    font-size: 1rem;
    margin: 0;
}
.card-section .cardsite {
    hyphens: auto;
    flex-flow: wrap;
}
.card-section .cardtown {

    hyphens: auto;
    flex-flow: wrap;
}
.card-section .cardclient {
    opacity: .8;
    font-size: .9rem;
    text-align: end;
    flex: auto;
    align-self: flex-end;
}
.card-section .status-bar {
    grid-area: sb;
}

.card-section > .secondary-section {
    width: auto;
}

.main-card .status-bar {
    width: 80%;
    height: 8px;
    border-radius: .5rem;
}

.status-bar {
    height: 5px;
    background: #ffffff;
    border-radius: .5rem;
}

.status-bar.canceled {
    background: linear-gradient(90deg, rgba(83, 0, 0, 0) 70%, rgba(4, 0, 0, 0.63) 100%);
}

.status-bar.received {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 70%, rgba(0, 75, 237, 0.59) 100%);
}

.status-bar.to-schedule {
    animation-duration: .7s;
    animation-name: status-animate;
    animation-iteration-count: infinite;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, rgb(255, 222, 0) 60%, rgba(218, 42, 42, 1) 100%);
}

.status-bar.scheduled {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 70%, rgb(255, 222, 0) 84%, rgba(0, 216, 75, 0.63) 100%);
}

.status-bar.processing {
    animation-duration: .8s;
    animation-name: status-animate;
    animation-iteration-count: infinite;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 70%, rgb(255, 128, 0) 100%);
}

@keyframes status-animate {
    0% {
        opacity: 1;
    }
    40% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}

.status-bar.done {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 70%, rgba(0, 216, 75, 0.6) 100%);
}

.status-bar.writing {
    background: darkcyan;
}

.card-section a {
    font-size: 1.3rem;
}

.card-section .card-intervention:hover {
    transform: scale(1.1);
    transition: all .5s ease;
    box-shadow: rgba(0, 0, 0, 0.2) 0 10px 5px;
}

.card-section .card-intervention {
    flex-wrap: nowrap;
    grid-area: i;
    box-shadow: rgba(0, 0, 0, 0.15) 0 5px 5px;
    background-color: rgba(255, 255, 255, .05);
}

.card-section .card-status {
    grid-area: s;
}

.card-section .card-type {
    grid-area: t;
}

.card-section .card-line-2 {
    grid-area: si;
    gap: min(.5rem, 5%);
    align-items: flex-start;
    flex-wrap: nowrap;
}

.card-section .card-line-2 div:before {
    font-size: .8rem;
    font-weight: normal;
    content: attr(title);
    opacity: .7;
    display: block;
}

.card-section .card-site {
    justify-content: space-between;
    width: 85%;
}

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, .15);
}

.main-card {
    background-color: rgba(255, 255, 255, 0.3);
    transition: all 0.5s ease-in-out;
    min-width: 32vw;
}

.main-card * {
    font-size: 1.1rem;
}

.main-card h1, .main-card a {
    font-size: 1.1rem;
}

.main-card .a-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 2fr));
    gap: 5px;
}

.main-card .grid .address {
    grid-column: span 2;
}

.main-card .grid > div {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: bold;
    height: 100%;
}

.main-card .grid > div:before {
    font-size: 1rem;
    font-weight: normal;
    content: attr(title);
    display: block;
    align-self: flex-start;
}

/*    mapPointer rules    */
.mapPointer {
    /*position: relative;*/
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    margin-bottom: 1rem;
    text-align: center;
    /*grid-area: a;*/
}

.mapPointer .search-area {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0.7rem 0.7rem;
}

.mapPointer .search-area .select-search {
    width: 25vw;
}

.mapPointer .search-area .search-suggest {
    margin-bottom: 0.2rem;
    padding: 0.1rem;
    background-color: rgba(51, 90, 206, 0.4);
    border: rgba(34, 57, 105, 0.78) 1px solid;
    color: white;
}

.mapPointer .search-suggest:before {
    content: url(../images/add-site.png);
}

.mapPointer .map {
    width: -webkit-fill-available;
    height: 400px;
    cursor: pointer;
}

.mapPointer .tools {
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    padding: 0 .5rem;
}

.mapPointer .screenshot {
    transition: all 0.5s ease-in-out;
    min-height: 50%;
    border: solid 2px rgba(255, 255, 255, 0.5);
    padding: 0.1rem 3rem;
}

.mapPointer .front img {
    max-width: 8rem;
    border: 1px rgba(0, 0, 0, 0.5) solid;
    cursor: zoom-in;
}

.totalContactForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 36rem;
    padding: 2rem;
}

/*    interactions rules    */
.interaction {
    font-size: 1rem;
}

.interaction h2 img {
    transform: scale(.6);
}

.interaction .interactionWrapper{
min-width: 46vw;
}

.interaction .onSitePhone {
    overflow: auto;
    background-color: rgba(0, 0, 255, 0.3);
    color: whitesmoke;
    margin-top: -2rem;
    justify-content: space-between;
}

.interaction .flex-column {
    justify-content: start;
    align-self: start;
}

.interaction .lights {
    right: 1rem;
    position: absolute;
}

.interaction .outputs {
    height: 54vh;
    width: 100%;
    min-width: 80%;
    overflow-y: auto;
    text-align: left;
    display: flow;
    background-color: rgba(217, 218, 232, 0.4);
}

.interaction .outputs .message {
    width: 96%;
    background-color: rgba(234, 231, 231, 0.91);
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px;
    padding: 0.5rem;
    margin: .2rem;
    font-weight: bold;
    font-size: medium;
}

.interaction .status {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    border: 2px rgba(0, 0, 0, 0.4) solid;
    filter: brightness(0.7);
}

.interaction .status:hover {
    filter: brightness(1.1);
}

.interaction .status.selected {
    border: 2px rgba(255, 255, 255, 0.6) solid;
    filter: brightness(1.2);
    transform: scale(1.1);
    padding: 1.5rem 0;
}

.interaction .status.selected:hover {
    filter: saturate(2);
}

.interaction .orange {
    background-color: rgba(255, 139, 0, 0.7);
}

.interaction .green {
    background-color: rgba(70, 152, 40, 0.7);
}

.interaction .red {
    background-color: rgba(255, 0, 0, 0.7);
}

.interaction .purple {
    background-color: rgba(140, 0, 255, 0.7);
}

.interaction .add-entree, .interaction .teamInteraction {
    grid-template-columns: 1fr;
    grid-template-rows: auto 8vh;
    grid-auto-rows: auto;
    padding: 1rem;
    gap: .5rem 0;
}
.bori{
    margin: -2rem 1rem auto auto;
    align-self: flex-end;
    right: 0;
}

.interaction .entree > .flex-row {
    justify-content: start;
    align-self: start;
    max-width: 94%;
    min-width: 47vw;
}

.interaction .entree .buttons {
    padding: 0.4rem;
    border-radius: 5rem;
    background-color: rgba(0, 123, 155, 0.95);
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin: auto;
    grid-column: span 2;
    width: max-content;
    transition: .3s ease all;
    opacity: .9;
    height: 5rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    transition-delay: .6s;
}

.interaction .entree .buttons button:hover {
    opacity: 1;
    filter: brightness(120%);
}
.interaction .entree .buttons button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    margin: -0.5rem;
    padding: 0;
}

.interaction .teamInteraction:before {
    margin: 0 1rem;
    font-weight: bold;
    color: rgba(0, 0, 128, 0.4);
    content: "Message pour l'équipe";
    text-align: start;
}

.interaction .teamInteraction textarea {
    width: 100%;
    height: 3rem;
}

.interaction textarea {
    min-height: 5rem;
    max-height: 100%;
    align-self: flex-start;
    border-radius: 0.4rem;
    padding: 0.5rem;
    border: 2px solid #004e8d;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.8);
    resize: vertical;
}

.interaction .flex-column p {
    align-self: end;
}

/*    CommandeData rules    */
.commande-data {
    grid-gap: 0.7rem;
    grid-template-columns: repeat(2, 25vw);
    text-align: left;
}

.commande-data .secondary-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.commande-data .secondary-section > div {
    text-align: center;
    height: 3rem;
}

.commande-data .secondary-section:before {
    content: attr(title);
    align-self: start;
}

.commande-data .search__control {
    width: unset;
}

/*    Planning rules    */
.planificator {
    min-width: 90vw;
    width: 100%;
}

.planificator .planningHead {
    grid-template-columns: 1fr 1fr 1fr;
    background-color: rgba(245, 245, 245, 0.3);
    padding: 0.8rem;
    margin: 0.3rem auto;
}

.planificator .planning {
    grid-template-columns: 1fr 1fr;
    height: 80vh;
    align-items: unset;
    position: relative;
}

.planificator .planning .container-list .container-table {
    overflow: auto;
    padding-left: 2.9rem;
    position: relative;
    margin: 0.3rem 0;
    max-height: 75vh;
}

.planificator .planning .container-list .container-table table {
    border-collapse: collapse;
}

.planificator .planning .container-list .container-table table thead {
    position: sticky;
    top: 0;
}

.planificator .planning .container-list .container-table table .step-container .agenda-container {
    position: relative;
    border-left: solid rgba(96, 96, 96, 0.4) 1px;
    border-right: solid rgba(255, 255, 255, 0) 1px;
}


/*    PlanningMap rules    */
.planning-map .plan-search {
    padding: 0 0 .5rem .5rem;
    gap: 0 0.5rem;
}

.planning-map .map {
    height: 91%;
    padding: 0 0 .5rem .5rem;
}

.planning-map .map > div > div:nth-child(1) {
    border: 1px solid #fff;
    box-shadow: 10px 15px 10px rgba(0, 0, 0, 0.2);
}

/*      Pin rules       */
.pin-container {
    height: fit-content;
    padding: 0;
    margin: 0;
    animation: drop-pin 1s ease-in-out;
    transform: translateY(-100%);
}

.pin-container.hovered {
    position: relative;
    z-index: 1;
}

.pin {
    border-radius: 50% 50% 50% 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform-origin: bottom left;
}

.pin.prioritize {
    animation: prioritize-pin 2s ease-in-out;
    animation-iteration-count: infinite;
}

.pin.waiting-for-event {
    animation: waiting-for-event-pin 3s ease-in-out;
    animation-iteration-count: infinite;
}

.pin.waiting-for-event.prioritize {
    animation: waiting-for-event-pin 3s ease-in-out, prioritize-pin 2s ease-in-out;
    animation-iteration-count: infinite;
}

.pin-text {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(10, 10, 10, 0.49);
    padding: .2rem;
    word-break: keep-all;
    font-size: .8rem;
    position: absolute;
    z-index: 1;
    width: 3rem;
    text-align: center;
}

.pin.square {
    border-radius: 0.2rem;
}

.pin.triangle {
    border-radius: 0;
    border: 15px solid transparent;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
    border-top: 0;
}

.pin.circle {
    transform: rotate(45deg) translate(-50%, 50%);
    border-radius: 50%;
}

.pin.hovered {
    filter: brightness(130%);
    border-radius: 0.3rem;
}

.pin.hovered span {
    background-color: black;
}

/*      PinCard rules       */
.pin-container .child {
    position: absolute;
}

.planning-card {
    padding: 0.3rem;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 15px 10px 0 rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    width: max-content;
    max-width: 15rem;
    font-size: .8rem;
    position: absolute;
    z-index: 10;
    cursor: default;
    animation: inflate .5s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.planning-card .intervention-type {
    padding: 0.2rem;
    color: white;
}

.planning-card .addEvent, .planning-card .editSupply {
    cursor: pointer;
}

.planning-card .addEvent::before {
    content: url("../images/add-event.png");
}

.planning-card .editSupply::before {
    content: url("../images/supplyPage.png");
}

.planning-card ul {
    font-weight: 900;
    cursor: pointer;
}

.planning-card ul::before {
    content: url("../images/contact-interaction.png");
}

.planning-card .showPlanningOfSelectedTeam {
    cursor: pointer;
}

.planning-card .showPlanningOfSelectedTeam::before {
    content: url("../images/planning-team-change.png");
}

/*      eventCreator rules       */
.eventCreator .content {
    display: grid;
    grid-template-columns: repeat(2, minmax(20vw, 33em));
    gap: .5rem;
    grid-template-rows: auto
}

.eventCreator .content .secondary-section .Calendar {
    display: inherit;
    width: 100%;
    height: 50%;
}

.eventCreator .span2 {
    grid-column: span 2;
}

.eventCreator .dateTimeDuration {
    font-size: 2rem;
    opacity: 0.4;
    font-weight: bold;
}

.eventCreator .Calendar {
    background: none;
    box-shadow: none;
}

.eventCreator .Calendar__monthYear {
    font-size: 1rem;
    width: 100%;
    justify-content: center;
}

.eventCreator .Calendar__weekDays {
    color: unset;
    font-weight: 900;
}

.eventCreator .Calendar__weekDays abbr:nth-last-child(-n+2) {
    color: red;
}

.timepicker-bubble {
    cursor: pointer;
}

.timepicker-bubble circle {
    fill: #0eca2d;
    opacity: 0.75;
    -webkit-transition: fill 0.25s linear, opacity 0.25s linear;
    -moz-transition: fill 0.25s linear, opacity 0.25s linear;
    -o-transition: fill 0.25s linear, opacity 0.25s linear;
    transition: fill 0.25s linear, opacity 0.25s linear;
}

.timepicker-bubble.small circle + circle {
    fill: transparent;
}

.timepicker-bubble.small circle + circle:hover {
    fill: #0eca2d;
}

.timepicker-bubble:hover circle, .timepicker-bubble .active circle {
    opacity: 1;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.7);
    filter: brightness(1.5);
}

.timepicker-bubble text {
    fill: #FFFFFF;
    font-weight: 400;
    text-anchor: middle;
    dominant-baseline: central;
}

.timepicker-hand {
    opacity: 0.8;
    stroke: #1b942f;
    stroke-width: 15;
    stroke-linecap: round;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
}

.timepicker-invisible {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.75);
}

.timepicker-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.timepicker-info {
    font-size: 2rem;
    color: #bac0c5;
    background-color: rgba(0, 0, 0, 0.7);
    margin: auto;
    padding: 1rem;
}

.timepicker-info-digits {
    font-size: 2rem;
    cursor: pointer;
    -webkit-transition: color 0.25s linear;
    -moz-transition: color 0.25s linear;
    -o-transition: color 0.25s linear;
    transition: color 0.25s linear;
}

.timepicker-info-digits.active {
    color: #2aff4d;

}


/*      Planning rules       */
.planning .hidden {
    display: none;
}

.container-list {
    grid-template-columns: 3.2rem repeat(7, 1fr);
    grid-auto-rows: 1fr;
    overflow: auto;
}

.container-list .header {
    position: sticky;
    top: 0;
    font-weight: bold;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0);
}
.container-list .header > span {
    background-color: rgba(255, 255, 255, 0);
    border: none;
}

.container-list .header:nth-child(2) {
    visibility: hidden;
}

.container-list .step-container .show-time {
    max-width: 3rem;
    text-align: start;
    border: none;
}

.agenda-line:nth-child(2n+1) .show-time:before {
    content: attr(name);
    font-size: 0.9rem;
    position: absolute;
    height: 10px;
}

.agenda-line {
    border-left: solid rgba(96, 96, 96, 0.4) 1px;
    position: relative;
}

.agenda-line:nth-child(4n+1) {
    border-top: dashed rgba(0, 0, 0, 0.25) 1px;
}

.agenda-line .hoverStepFlat {
    border: rgba(0, 197, 46, 0.5) 2px solid;
    background: linear-gradient(rgba(0, 155, 8, 0.6), rgba(200, 0, 0, 0));
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 0;
}

.agenda-line .hoverStepFlat:before {
    content: attr(name);
    top: -30px;
    left: -40px;
    position: absolute;
    padding: 0.3rem;
    border: white 1px solid;
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 10px;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    color: #ffffff;
    background: linear-gradient(rgba(7, 0, 0, 0.84), rgba(0, 0, 0, 0.7));
    z-index: 12;
}

.agenda-line .event {
    z-index: 1;
}

.agenda-line .event:hover {
    z-index: 5;
}

.event .react-resizable {
    background: var(--stepflat-color);
    border: solid 1px var(--div-border);
    border-bottom: rgba(37, 196, 92, 0.6) 1px solid;
    border-radius: 0.3rem;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    color: var(--text);
    cursor: grab;
    text-overflow: ellipsis;
    position: relative;
}

.event .react-resizable-handle {
    z-index: -1;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
    top: 0;
    cursor: row-resize;
}

.event .react-resizable.fix {
    background: linear-gradient(rgba(5, 50, 66, 0.67), rgba(23, 72, 103, 0.6));
    border-radius: 0.3rem;
    color: #dcdcdc;
    text-shadow: 0 0 3px black;
    text-overflow: ellipsis;
    opacity: 0.9;
    cursor: grab;
}

.event .react-resizable:not(.content).hovered {
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.6);
    border: 1px rgba(255, 255, 255, 0.6) solid;
    transition: all 0.1s;
}

.event .react-resizable.hovered .react-resizable-handle {
    border-bottom: 6px solid rgb(37, 197, 39);
    border-radius: 9px;
    transition: all ease-in-out;
}

.step-container .event .react-resizable .react-resizable-handle:hover {
    animation: pulse-red 0.5s;
    animation-delay: 0.5s;
}

.event .react-resizable .react-resizable-handle:hover:after {
    content: "↕";
    bottom: -10px;
    margin: auto;
    left: 0;
    right: 0;
    max-width: 8px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    padding: 0.5rem 0.8rem;
    border-radius: 1rem;
    position: absolute;
    font-size: 0.8rem;
    cursor: ns-resize;
}

.event .react-resizable .step-flat {
    overflow: hidden;
    height: 100%;
}

.event .react-resizable .step-flat .siteName {
    margin-left: .7rem;
}

.event .react-resizable .step-flat span.alert {
    background-color: rgba(255, 0, 0, .6);
    width: .5rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.event .react-resizable .step-flat span.alert.orange {
    background-color: rgba(255, 139, 0, .7);
}

.event .react-resizable .step-flat span.alert.green {
    background-color: rgba(70, 152, 40, .7);
}

.event .react-resizable .step-flat span.alert.purple {
    background-color: rgba(140, 0, 255, .7);
}

.event .popper {
    z-index: 2;
    min-width: 10vw;
}

.event .popper .exit {
    font-weight: bold;
}

.event .popper span:active:not(.date-intervention) {
    cursor: grabbing;
    opacity: 0.2;
    transition: 0.3s all ease-in-out;
}

.event .popper span:hover:not(.date-intervention) {
    opacity: 0.6;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.event .popper span.delete {
    content: url("../images/planning-delete.png");
}

.event .popper span.appro {
    content: url("../images/planning-doc.png");
}

.event .popper span.time {
    content: url("../images/planning-edit-interv.png");
}

.event .popper span.contact {
    content: url("../images/planning-contact.png");
}

.event .popper span.add {
    content: url("../images/planning-new-interv.png");
}

.event .popper span.reset {
    content: url("../images/planning-edit-interv.png");
}

.event .popper span.changeTeam {
    content: url("../images/planning-team-change.png");
}

.event .popper .date-intervention::before {
    content: url("../images/icon-cal.png");
    margin-right: 0.5rem;
}

.eventTooltip {
    position: relative;
    left: -0.7rem;
}

.container-list .hr {
    height: 2px;
    background: rgba(255, 0, 0, 0.3);
    position: relative;
    grid-column: span 8;
}

/*      MapBean rules       */
.map-bean {
    width: 90vw;
    height: 85vh;
}


/*      Command&Quotation rules       */
.devis-bean {
    align-items: center;
    justify-content: start;
    grid-row-gap: .3rem;
    column-gap: .5rem;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 5rem;
    height: fit-content;
}

.stepper {
    grid-column: span 10;
}

.devis-bean .references {
    grid-column: span 3;
    text-align: center;
    align-self: center;
    max-height: 20vh;
}

.devis-bean .references .ref-edit:before {
    content: "Référence : ";

}
.devis-bean .references .ref-edit {
    min-width: 12rem;
    padding: 0.3rem;
}

.devis-bean .documents {
    grid-column: span 2;
    flex-wrap: wrap;
    max-height: 20vh;
    overflow: auto;
}

.devis-bean .documents .flex-column {
    width: 5rem;
}

.devis-bean .infos {
    grid-auto-rows: 1fr;
    grid-column: span 5;
    padding: 1rem;
}

.devis-bean .details, .devis-bean .comments {
    grid-column: span 10;
    padding-bottom: 2rem;
    text-align: left;
    align-items: start;
}

.comments textarea {
    background-color: rgba(255, 255, 255, 0.35);
}

.comments .textedit {
    font-weight: bold;
    height: auto !important;
    min-height: 88px;
}

.comments span {
    font-weight: bold;
}

.devis-bean .tabrow {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    justify-items: start;
    border-image-repeat: initial;
    align-items: center;
    background-color: var(--interv-bg-even);
}

.devis-bean .tabrow:nth-child(2n+1) {
    background-color: var(--td-cell-hover);
}

.devis-bean .tabrow input {
    max-width: 50%;
}

.devis-bean .tabcell {
    display: inline-flex;
    padding: .5rem;
    font-size: 1.2rem;
    font-weight: 600;
    align-items: stretch;
}

.tabcell .tabinput {
    border-radius: 0.3rem;
    height: 2rem;
    min-width: 5.2rem;
    padding: 0.2rem;
    margin: 0 .5rem;
    text-align: center;
}

.devis-bean .secondary-section {
    border: 2px white solid;
    border-radius: 1.8rem;
}

.devis-bean .tabcell:nth-child(even) {
    text-align: end;
}

/*      QuotationForm rules       */
.devis-bean .details .quotation > div.flex-column {
    align-items: stretch;
}

.devis-bean .chapter-title {
    font-size: 1.3rem;
    font-weight: 600;
    background: whitesmoke;
    height: 3rem;
    align-content: center;
}

.devis-bean .flex-column {
    gap : .1rem
}

.devis-bean .add-product:hover {

    transition: all ease .3s;
    padding: .2rem 7vw;
    width: 28rem;
    background-color: green;
}
.devis-bean .add-product {
    margin-right: auto;
    display: block;
    width: 23rem;
    padding: .2rem 3vw;
    transition: all ease .3s;
    background-color: rgba(0, 128, 0, 0.4);
    color: white;
}
.devis-bean .add-section:hover {
    transition: all ease .3s;
    padding: .2rem 10vw;
    background-color: green;
}

.devis-bean .add-section {
margin-right: auto;
    display: block;
    transition: all ease .3s;
    background-color: rgba(0, 128, 0, 0.5);
    color: white;
}

.devis-bean img {
    max-height: 2rem;
}

.devis-bean .cancel {
    padding: 0;
}

.devis-bean .cancel.delete-chapter {
    transition: width .5s ease-in-out;
    height: 2rem;
    width: 2rem;
    margin-left: auto;
}
.devis-bean .cancel.delete-chapter:hover {
    transition: width .5s ease-in-out;
    padding: .5rem .5rem;
    width: 10vw;
    max-width: 10vw;
}

.devis-bean .cancel.delete-chapter:after {
    position: absolute;
    content: attr(title);
    padding: 0 .5rem;
    opacity: 0;
    transition: unset;
    font-size: medium;
}
.devis-bean .cancel.delete-chapter:hover:after {
    position: unset;
    opacity: 1;
    transition: opacity .3s ease .1s;
}

.devis-bean .cancel.delete-section {
    top: 0;
    right: 0;
}

.devis-bean .poi-chapter {
    position: relative;
    align-items: center;
    margin-bottom: .4rem;
}

.except-addBtn > *{
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: .2rem;
}

.except-addBtn{
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
}

.devis-bean .poi-chapter .selectable {
    height: 100%;
}

.devis-bean .poi-chapter input {
    margin: 0;
    height: 100%;
}

.devis-bean .add-prod {
    margin-top: .5rem;
    grid-column: span 11;
    width: fit-content;
    justify-self: center;
}

.devis-bean .poi-chapter .comments {
    grid-column: span 11;
    margin-top: 1rem;
    align-items: stretch;
    text-align: start;
    width: inherit;
}

.devis-bean .table-header {
    background: var(--link);
    color: white;
    font-weight: bold;
    height: 3rem;
    margin: .2rem;
    border-radius: .3rem;
}

.devis-bean .poi-chapter .product-name {
    text-align: start;
    grid-column: span 2;
}

.devis-bean .poi-chapter .clientinfo-title {
    opacity: .3;
    font-size: 1.7rem;
    font-weight: 900;
}

.poi-chapter .as-link {
    font-size: 1.2rem;
    align-self: start;
}

.poi-chapter .selected {
    background: rgba(0, 255, 0, 0.3);
}

.devis-bean .draggable-block > div {
    position: relative;
}
.devis-bean .draggable-icon {
    width: 4rem;
    height: 4rem;
    z-index: 1;
    padding: .5rem;
}
.devis-bean .draggable-icon .anchor-icon span {
    height: 3px;
    background-color: var(--link);
    margin: 6px 0;
    display: block;
}

.quotation-form {
    gap: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.quotation-form .remove-line {
    transform: scale(.6);
}

    .fastMode .fastModeContent {
    overflow: auto;
    height: 100%
}

.fastMode>div>.page .intervention {
    display: flex;
    flex-direction: column;
}

.fastMode .fastModeContent .page .criteria-bar{
    top: 3rem;
    left: 3rem;
    transform: none !important;
    transition: none;
}
.fastMode .fastModeContent .page .criteria-bar:hover{
    left: 3rem;
    transform: none !important;
    transition: none;
}

.intervention .table-row.header {
    position: unset;
}

.page > .intervention {
    grid-template-columns: repeat(10, 1fr);
    align-items: stretch;
    grid-row-gap: .3rem;
    column-gap: .5rem;
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    overflow: auto;
}

.pageTitleImg {
    transform: scale(.5);
}

.pageTitle {
    opacity: .5;
    position: absolute;
    left: 5rem;
}

.intervention .status {
    position: absolute;
    top: 0;
    right: .5rem;
}

.intervention .status button {
    padding: 0;
}

.intervention .client {
    grid-column: span 5;
}

.intervention .plan {
    grid-column: span 5;
    grid-row: span 3;
    justify-content: start;
    contain: content;
}
.intervention .plan img {
    width: 100%;
}

.intervention .documents, .object, .added-operation {
    grid-column: span 3;
    z-index: 2;
    justify-content: start;
}

.intervention .secondary-section {
    justify-content: center;
}

.intervention .signature {
    grid-column: span 2;
    grid-row: span 2;
}

.intervention .signature img {
    max-width: 90%;
}

.intervention .added-operation h2:before {
    content: url("../images/mandatory-icon.png");
}

.intervention .resume {
    grid-column: span 7;
    justify-content: start;
}

.intervention .de-section {
    max-width: 45vw;
    width: 35%;
}
.intervention .de-section .tabcell input{
    width: 20vw;
    height: 1.5rem;
}

.intervention .de, .infos, .rapport, .conclusion, .risk-analysis {
    grid-column: span 10;
    justify-content: space-around;
}


.intervention .risk-analysis {
    margin-bottom: 2rem;
}

.intervention .risk-analysis .flex-row {
    flex-wrap: wrap;
    align-items: stretch;
}

.intervention .risk-analysis .card {
    width: 10vw;
    text-align: center;
}

.intervention .rapport .workforce-btn {
    align-self: start;
}

.layer .extensible-component.hovered {
    align-items: start;
    padding: 1rem;
}

.operation-table {
    border-radius: 0.7rem 0.7rem 0.3rem 0.3rem;
    border-spacing: 1px;
    margin: 1rem auto;
    text-align: center;
    color: var(--interv);
}

.operation-table td {
    background-color: hsla(0, 0%, 100%, .3);
    border: 1px solid var(--ul);
    transition: all .3s ease-in-out;
    min-height: 18vh;
}

.operation-table td:first-of-type {
    background-color: initial;
    border: none;
}

.operation-table .operation-row {
    position: relative;
    height: 18vh;
}

.operation-table:not(.for-mobile) td.commentaire-cell {
    max-width: 15vw;
}

.operation-table:not(.for-mobile) td > div {
    height: 100%;
    overflow: auto;
}

.operation-table td > * {
    margin-bottom: 1.5rem;
}

.operation-table td.commentaire-cell ul {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.operation-table td.commentaire-cell ul.report-item li {
    margin-bottom: 10px;
}

.operation-table td.commentaire-cell textarea {
    height: 12vh;
    background-color: hsla(0, 0%, 96%, .5);
    border: 1px solid #fff;
    border-radius: 0.3rem;
    font-size: .9rem;
    padding: 0.5rem;
    transition: height .5s ease-in-out, width .5s ease-in-out;
}

.operation-table td.commentaire-cell textarea:focus-within, .operation-card .commentaire-cell textarea:focus-within {
    backdrop-filter: blur(10px);
    background-color: hsla(0, 0%, 100%, .8);
    border: 3px solid #fff;
    border-radius: 0.7rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    font-size: 1rem;
    position: fixed;
    max-width: 80vw;
    height: 70vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
}

.operation-table:not(.for-mobile) td.operation-picture {
    max-width: 20vw;
    overflow: auto;
}


.operation-card {
    margin: .5rem;
    padding: .5rem;
}
.operation-card .operation-picture {
    padding: .5rem;
    overflow: auto;
}
.operation-card .expandable-div .tl-corner {
    top: -4.1rem;
    right: .2rem;
}

.operation-table td.operation-picture .flex-row {
    justify-content: start;
}

.operation-table .family, .operation-card .family {
    padding: 0.3rem 0.5rem;
}

.operation-table .family img, .operation-card .family img {
    border: 1px solid hsla(0, 0%, 100%, 0);
    border-radius: 0.5rem;
    cursor: pointer;
    max-height: 8rem;
    min-width: 4rem;
}

.section .suggestion > * {
    font-size: 1.2rem;
    font-weight: 900;
}

.operation-table td.operation-picture .hidden {
    opacity: .5;
}


.rapport .primary-section {
    overflow: auto;
}

.operation-table.for-mobile td:first-of-type {
    height: 8vh;
}
.operation-table.for-mobile tbody {
    align-items: start;
}
.operation-table.for-mobile .operation-row {
    display: block;
    float: left;
    height: 110vh;
}
.operation-table.for-mobile th, table.for-mobile td {
    display: block;
    min-height: unset;
}
.operation-table.for-mobile td.commentaire-cell, .operation-table.for-mobile td.operation-picture {
    width: 50vw;
    height: 25vh;
    overflow: auto;
}
.operation-table.for-mobile td.operation-picture .flex-row {
    flex-wrap: wrap;
}


.caroussel {
    max-height: 72vh;
    top: -2rem;
}

.caroussel.flex-row{
    gap: 0
}
.carousselImg{
max-height: 70vh;
}
.caroussel .keep-space {
    width: 24vw;
    overflow: hidden;
}

.caroussel img {
    cursor: grab;
    -webkit-user-drag: none;
    transform-origin: center;
    max-height: 72vh;
}

.caroussel img:active {
    cursor: grabbing;
}

.caroussel.full-page {
    max-height: unset;
}

.caroussel.full-page img {
    max-height: 85vh;
}

.track-file {
    margin: 1rem 0 1rem 0;
}

.trackFile-header {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.track-file .intervState {
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
}

.track-file .intervention {
    display: flex;
}

.track-file .intervention .infos {
    width: fit-content;
    margin: auto;
    gap: 1rem;
}

.track-file .intervention .infos .documents {
    grid-column: span 1;
}

.track-file .intervention .infos .documentLoaderList {
    height: 100%;
}

.layer .selected-product {
    text-align: start;
}

.layer .selected-product {
    align-content: start;
    text-align: start;
}

/*      Site rules       */
.site-header h1:before {
    content: url("../images/site-name.png");
}


    /*      Notification rules       */
.notification-bean {
    margin: auto;
    width: 90%;
    align-items: stretch;
    font-weight: 900;
}

.notification-bean .col {
    height: 90vh;
    overflow: auto;
    justify-content: start;
}

.notification-bean .pointer {
    text-align: start;
    padding: 0 1rem;
}

.notification-bean .alert:before {
    border-bottom-right-radius: 3px;
    border-right: 2px solid;
    border-top-right-radius: 3px;
    content: "";
    height: calc(100% - 44px);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.notification-bean .alert.unseen:before {
    border-right: 4px solid;
    box-shadow: 0 0 55px #ff1616;
    color: #ff1d10;
}

/*      Client rules       */
.client-bean {
    width: 80vw;
    margin: auto auto 5rem auto;
}

.client-header h1:before {
    content: url("../images/client.png");
    height: 3rem;
}

.client-header .primary-section {
    position: relative;
    transition: all ease-in-out .5s;
}

.client-grid {
    grid-gap: .5rem;
    display: flex;
    flex-wrap: wrap;
}

.clientSiteList {
    background-color: rgba(255, 255, 255, 0.2);
}

.clientSiteList > ul {
    display: flex;
    gap: .5rem;
}

.client-info {
    min-height: 3rem;
    min-width: 10rem;
    padding: .5rem
}

.client-grid div:before {
    display: block;
    content: attr(title);
    font-weight: 700;
    font-size: .8rem;
    opacity: .5;
    width: fit-content;
}

/*      Supply Modal rules       */
.supply {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-gap: .5rem;
    width: 95vw;
    max-height: 85vh;
}
.supply > .secondary-section {
    padding: 0;
}

.supply .extensible-component-wrapper {
    position: relative;
    height: fit-content;
}
.supply .extensible-component-wrapper .extensible-component {
    top: -3rem;
}
.supply .extensible-component::after {
    line-height: 0;
    font-size: 3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.supply > .secondary-section {
    display: grid;
    grid-template-rows: 3rem 1fr;
}

.supply .secondary-section .case-title {
    align-items: flex-end;
    font-weight: 600;
    font-size: 1.5rem;
}
.supply-list {
    overflow: auto;
    margin: .9rem .5rem;
}

.supply .secondary-section .case-title img {
    max-height: 3rem;
}

.supply.supply-mode {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50vh 34.5vh;
}

.supply.supply-mode .material-case {
    grid-column-start: 1;
    grid-column-end: -1;
}

.supply.supply-mode .doc-case {
    grid-column-start: 1;
    grid-row-start: 2;
}

.supply.supply-mode .machinery-case {
    grid-column-start: 2;
    grid-row-start: 2;
}

.supply.supply-mode .admin-doc-case {
    grid-column-start: 3;
    grid-row-start: 2;
}

.material-case {
    background-color: rgba(0, 128, 0, 0.15);
}

.doc-case {
    background-color: rgba(199, 0, 0, 0.15);
}

.admin-doc-case {
    background-color: rgba(0, 30, 128, 0.15);
}

.machinery-case {
    background-color: rgba(255, 174, 0, 0.16);

}

.supply.supply-mode .secondary-section.material-case:not(.images) {
    grid-template-rows: auto 1fr;
}

.supply .secondary-section.images {
    justify-content: space-between;
}

.supply ul {
    min-height: 12vh;
    height: 33vh;
    overflow: auto;
    place-items: stretch;
    max-height: 35vh;
}
.supply.supply-mode .material-case .supply-list ul{
    height: 40vh;
}
.supply.supply-mode ul:not(.material){
    height: 25vh;
}

.supply ul li div, .supply ul li b {
    font-size: 1.2rem;
    padding: .2rem;
    font-weight: bold;
}

.supply ul li {
    border: 1px solid white;
    padding: .5rem;
    position: relative;
}

.supply ul li .info {
    position: absolute;
    top: -.5rem;
    left: 0;
}

.supply ul li > .material {
    display: grid;
    align-items: center;
    column-gap: .2rem;
}

.supply ul li .docs {
    grid-template-columns: 3fr 2fr 2fr .5fr .5fr;
    align-items: center;
}

.supply ul li .material>* {
    height: 3rem;
    align-items: center;
}

.supply ul li .material {
    grid-template-columns: .6fr .8fr 1.8fr .7fr .4fr 1fr .7fr;
    font-weight: 900;
    gap: .3rem;
}

.supply ul li .material a {
    display: inline-grid;
    width: max-content;
}

.supply.supply-mode ul li .material {
    grid-template-columns: 1fr .5fr .5fr 2fr .1fr .2fr .8fr 1fr;
    font-weight: 900;
}

.supply ul li:nth-child(even) {
    background: rgba(255, 255, 255, .5);
}

.supply ul li:hover {
    background: rgba(255, 255, 255, .5);
    text-shadow: var(--highlighted-text-shadow);
    transition: all 0.2s ease-in-out;
}

.supply ul li .machinery {
    grid-template-columns: 2fr 1fr 1fr 1.2fr .5fr 1.2fr;
    font-weight: 900;
    align-items: center;
}

.supply ul li p {
    height: 3rem;
    position: relative;
    overflow-x: auto;
    justify-content: start;
    text-align: start;
}

.supply ul li p b {
    position: absolute;
    width: max-content;
}

.supply .fileUploader {
    width: 2rem;
}

.supply.supply-mode .fileUploader {
    width: unset;
}

.supply ul li .material textarea {
    height: 3rem;
    background-color: hsla(0, 0%, 100%, .8);
    border: 1px solid rgba(4, 0, 0, 0.63);
    border-radius: 0.3rem;
    font-size: .9rem;
    padding: 0.5rem;
    transition: width .5s ease-in-out;
    resize: none;
}

.supply ul li .material textarea:focus-within {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: hsla(0, 0%, 100%, .8);
    border: 3px solid #fff;
    border-radius: 0.7rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    font-size: 1rem;
    position: absolute;
    width: 90vw;
    height: 50vh;
    top: 2rem;
    z-index: 2;
    resize: vertical;
    transition: height .5s ease-in-out, width .5s ease-in-out;
}

/*      Misceallenous Modal rules       */
.form.flex-column {
    align-items: stretch;
}

.cancel-intervention {
    align-items: stretch;
}

.client-form h1 {
    margin: 0;
}

.client-form h1:before {
    content: url("../images/button-new-client.png");
    transform: scale(.8);
}

.pdf-modal {
    min-height: 80vh;
    min-width: 80vw;
}

/*      TimeSheet rules       */
.time-sheet {
    padding: 1rem;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-gap: .5rem;
}

.time-sheet .search-bar {
    margin: auto;
    width: max-content;
    grid-column: span 2;
}

.time-sheet .search-bar input {
    margin: auto;
    width: 15rem;
}

.time-sheet aside {
    max-height: 81vh;
}

.time-sheet .timeline {
    overflow: auto;
    flex-wrap: nowrap;
}

.time-sheet .separator-dot {
    padding: 0;
    height: 2rem;
    align-items: center;
    gap: 0;
}

.time-sheet .separator-dot.primary {
    background-color: #f0c400;
    border-color: #b38300;
}

.time-sheet .separator-dot.secondary {
    background-color: rgba(184, 9, 9, .9);
}

.time-sheet .separator-dot.secondary .status-pic {
    visibility: hidden;
}

.time-sheet .status-pic {
    background: #1356bb;
    height: 1.2rem;
    width: 1.2rem;
}

.time-sheet #onriskanalysis, .time-sheet #finished, .time-sheet #canceled {
    height: .8rem;
    width: .8rem;
}

.time-sheet #onriskanalysis {
    transform: rotate(45deg);
}

.time-sheet #active {
    clip-path: polygon(20% 20%, 20% 90%, 90% 55%);
}

.time-sheet #paused {
    clip-path: polygon(20% 21%, 20% 75%, 40% 75%, 41% 21%, 61% 21%, 61% 74%, 82% 74%, 82% 21%);
}

.time-sheet #exitedzone, .time-sheet #backonzone {
    clip-path: polygon(0 40%, 60% 40%, 40% 0, 100% 50%, 40% 100%, 60% 60%, 0 60%);
    transform: rotate(-45deg);
}

.time-sheet #backonzone {
    transform: rotate(135deg);
}

.timeline-card {
    position: relative;
    background-color: var(--td-cell);
    border-radius: 0.2rem 0.5rem 0.5rem 0.5rem;
    color: #000;
    font-weight: 700;
    max-height: 8rem;
    max-width: 15vw;
    padding: 0.2rem 0.5rem 0.5rem 0.2rem;
    transition: all .5s ease-in-out;
}

.timeline-card:hover, .timeline-card.hovered {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .47);
    z-index: 1;
    max-height: unset;
}

.timeline-card .infos {
    position: relative;
    max-width: 10vw;
}

/*      Parameter rules       */
.parameter {
    padding: 0 5%;
    height: 100%;
    overflow: auto;
    display: block;
}

.parameter > div:first-of-type {
    align-items: stretch;
    margin: 2rem 0;
}

.parameter h1:before {
    content: url("../images/banicon-param.png");
}

.parameter .custom-accordion {
    background: hsla(0, 0%, 100%, .2);
    border: 2px solid var(--div-border);
    box-shadow: none;
    padding: 1rem 0;
}

.parameter .accordion-details {
    width: 95%;
    margin: auto;
    justify-content: start;
    align-items: stretch;
}

.parameter .accordion-details .user-list ul {
    height: 30vh;
    overflow: auto;
    justify-content: start;
    align-items: stretch;
}

.parameter .edit-company {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: .5rem;
}

.parameter .edit-company .user-infos {
    grid-column: span 4;

}
.parameter .edit-company .company-info {
    grid-column: span 2;
    grid-row: span 2;
    align-items: stretch;
}

.parameter .edit-company .company-info .editable-img img {
    border: 1px solid whitesmoke;
    border-radius: .5rem;
    width: 12rem;
}

.parameter .edit-company .company-info .editable-img:hover {
    opacity: .5;
    cursor: pointer;
}

.parameter .edit-company .company-info .editable-img:hover:before {
    content: url("../images/import.png");
    position: absolute;
}

.parameter .edit-company .company-info .editable-img .fileUploader {
    padding: 0;
    visibility: hidden;
    height: 0;
    width: 0;
}

.parameter .edit-company .company-info span, .parameter .edit-company .company-info b {
    font-size: 1.2rem;
}

.parameter .contactForm > * {
    display: flex;
}

.parameter .parameter-widget {
    font-weight: 900;
}

.parameter .sector-creator:before {
    content: url("../images/secteur.png");
}

.parameter .risk-selector:before {
    content: url("../images/risk-ana.png");
}

.parameter .style-selector:before {
    content: url("../images/theme-icon.png");
}

.parameter .storm-mode {
    grid-column: span 4;
}

.parameter .storm-mode:before {
    content: url("../images/storm-mode.png");
}

.parameter .stats * {
    font-size: 1.2rem;
}

.parameter .stats .header {
    grid-template-columns: 30vw 1fr;
}

.parameter .stats .flex-column {
    align-items: stretch;
}

.parameter .stats .graph-table {
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
}

.parameter .stats .graph-table .table-interventions {
    justify-items: stretch;
    align-items: stretch;
    font-weight: 900;
}

.parameter .stats .graph-table .table-interventions.col4 {
    grid-template-columns: repeat(4, 1fr);

}

.parameter .stats .graph-table .table-interventions.col5 {
    grid-template-columns: repeat(5, 1fr);

}

.parameter .stats .graph-table .table-interventions .head {
    color: hsla(0, 0%, 0%, 0.4);
}

.parameter .stats .graph-table .table-interventions .title {
    grid-column: span 4;
    color: black;
}

.parameter .stats .graph-table .table-interventions .dual-value {
    grid-template-columns: 1fr .2fr 1fr;
    align-items: center;
    border: 1px solid darkgrey;
}

/*    Admin Page rules    */
.admin {
    grid-template-columns: 15% 1fr;
    margin: 1rem 1rem;
    column-gap: 1rem;
    height: 90vh;
}

.admin aside {
    align-items: stretch;
}

.admin article {
    overflow: hidden;
}

.admin article h1 {
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    text-decoration: underline;
}

.admin .contact-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
    padding: 0 2rem;
}

.admin .company-crud span {
    align-self: stretch;
    grid-template-columns: 200px 1fr;
}

.admin .ra-grid {
    grid-template-columns: repeat(3, 1fr);
    width: 50vw;
}

.edit-ra-modal {
    grid-template-columns: 1fr 1fr 1fr;
    overflow: hidden;
    column-gap: 10px;
}

.edit-ra-modal > div {
    overflow: auto;
}

.edit-ra-modal .questionConfigDnd > li {
    border: 1px solid rgb(128, 128, 128);
    border-radius: .5rem;
    margin-bottom: 5px;
}

.edit-ra-modal .questionConfigDnd .answer-list {
    margin-left: 15px;
}

.edit-ra-modal .sticky-bottom, .admin .sticky-bottom {
    position: sticky;
    bottom: 0;
}

.edit-ra-modal .scinded, .admin .scinded {
    grid-template-rows: 1fr 1fr;
    row-gap: 15px;
}

.edit-ra-modal .scinded > div, .admin .scinded > div {
    overflow: auto;
}

.admin .edit-job {
    grid-template-columns: repeat(5, 1fr);
    overflow: hidden;
    column-gap: 10px;
    height: 84vh;
}

.admin .edit-job > div {
    overflow: auto;
    padding: 1rem;
}

.admin .family-tab {
    max-height: 79vh;
}

.admin .family-tab ul {
    max-height: 60vh;
    overflow: auto;
}

.admin .catalog-content ul.w100 {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
}
.admin .catalog-content li .secondary-section {
    max-width: 10vw;
    justify-content: start;
    flex-direction: column;
}
.admin .catalog-content ul li h2 {
    padding-top: 2rem;
}
.admin .catalog-content .add-carac {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(14vw, 1fr));
    align-items: center;
    z-index: 1;
}


.notification-container {
    position: absolute;
    top: 5rem;
    right: 3rem;
    z-index: 2;
    max-width: 25%;
}

.notification-container .notification-message {
    color: whitesmoke;
    padding: 1rem;
    border: 1px solid #EEE;
    background-color: var(--nav-bar);
    box-shadow: inset 0 10px 10px 10px rgba(0, 0, 0, 0.02), 0 5px 10px rgba(0, 0, 0, .075);
    backdrop-filter: blur(10px);
    white-space: pre;
    border-radius: 1rem;
}
.notification-container .notification-message .title {
    margin: 0;
}



/*    ExtensibleComponent rules    */


.extensible-component {
    width: 3rem;
    min-height: 3rem;
    overflow: hidden;
    position: absolute;
    transition: all .4s ease-in-out;
    z-index: 3;
    background-color: rgba(159, 190, 255, 0.6);
}

.extensible-component:not(.hovered)::after {
    font-weight: 600;
    color: white;
    content: attr(title);
    position: absolute;
    animation-delay: 1s;
}
.extensible-component > * {
    visibility: hidden;
}
.extensible-component.hovered {
    width: 90%;
    position: absolute;
}
.extensible-component.hovered > * {
    visibility: visible;
    height: 50%
}


/*    Reactive rules    */
@media screen and (max-width: 915px) {

    .myBody {
        grid-template-rows: 5vh 95vh;
    }

    .company-creation {
        max-width: 90vw;
        margin-top: -5rem;
    }

    .navbar {
        transition: all ease 1s;
        height: 100%;
        width: 100vw;
    }
    .navbar > * {
        height: 55px;
    }

    .navbar.opened {
        height: 100vh;
        z-index: 5;
    }

    .navbar ul {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        position: fixed;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: -100%;
        height: 0;
        width: 100%;
        transition: all ease 1s;
    }

    .navbar .burger-btn {
        height: 100%;
        display: flex;
        background: var(--nav-bar);
        justify-content: end;
        padding-right: 1rem;
        align-items: center;
        max-height: 2.5rem;
    }

    .navbar .logo {
        height: 100%;
        max-height: 2.5rem;
    }

    .navbar .burger-btn:target ul {
        display: flex;
        flex-direction: column;
    }

    .burger-icon {
        z-index: 3;
    }

    .burger-icon span {
        display: block;
        width: 40px;
        height: 3px;
        background-color: whitesmoke;
        border-radius: 1rem;
        margin: 6px 0;
        transition: all ease-out .5s;
    }

    .burger-btn.opened span.row1 {
        transform: rotate(135deg) translate(4px, -4px);
    }

    .burger-btn.opened span.row2 {
        opacity: 0;
    }

    .burger-btn.opened span.row3 {
        transform: rotate(-135deg) translate(8px, 10px);
    }

    .criteria-bar {
        transform: scale(.8) translate(-62%, 95%);
        border-radius: 5rem;
        transition: all ease-out .3s;
        top: unset;
        bottom: 0;
        left: 50%;
        width: 95vw;
        position: fixed;
    }
    .criteria-bar .margin-content {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .criteria-bar:hover {
        transform: scale(.8) translate(-62%, 95%);
        opacity: unset;
        transition: all ease-out .3s;
    }

    .criteria-bar.developped {
        opacity: 1;
        transform: scale(.8) translate(-62%, 10%);
    }

    .criteria-bar .only-for-small-screen {
        border-radius: 0 2rem 2rem 0;
        background: var(--menu-arrow-bg);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        border: 1px solid var(--interv-thead);
        font-size: 3rem;
        color: var(--menu-arrow);
        line-height: 3.5rem;
        width: 4rem;
        height: 4rem;
        transition: all ease .3s;
        opacity: 1;
        position: fixed;
        top: -5vh;
        transform: rotate(-90deg);
    }

    .grid-card-scrollable {
        overflow: auto;
        height: 100vh;
        padding-bottom: 8rem;
    }

     .filter-bar {
         height: 8rem;
         margin: 0;
         flex-direction: column;
     }

     .pager{
         position: absolute;
         top: 6.5rem;
     }

    .criteria-bar .margin-content .only-for-small-screen.developped {
        transform: rotate(90deg);
        background: rgba(255, 255, 255, 45%);
        color: var(--menu-arrow-bg);
        border-radius: 2rem 0 0 2rem;
        border: none;
    }

    .list-page {
        margin: 0;
    }

    ul.opened {
        height: 100%;
        top: 0;
        position: fixed;
        z-index: 2;
        padding: 0;
    }

    .not-on-small-screen {
        display: none !important;
    }

    div .only-for-small-screen {
        display: block;
    }

    .cell a.id::before {
        content: unset;
    }

    .mapPointer .search-area .select-search {
        width: 61vw;
    }

    .mapPointer .tools {
        grid-template-columns: 1fr 3fr;
        justify-content: space-between;
        align-items: center;
        padding: 0 .5rem;
    }

    .mapPointer .tools .info-tools {
        display: none;
    }

    .commande-data {
        grid-template-columns: 80vw;
    }

    .planificator {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .planificator .planningHead {
        flex-wrap: wrap;
        margin: 0;
        width: 93vw;
    }

    .planificator .planning {
        max-height: 70vh;
        width: fit-content;
    }
    .planificator .planning .container-list .event .popper {
        width: 50vw;
        display: flex;
    }

    .planificator .planning .container-list, .planificator .planning-map {
        width: 93vw;
    }

    .planificator .planning-map {
        height: inherit;
    }

    .planning-switcher {
        position: absolute;
        left: 50%;
        transform: translateX(-48%);
        gap: 2.5rem;
        bottom: 0;
    }

    .event .react-resizable .step-flat .siteName {
        font-size: .7rem;
    }

    .container-list .header, .agenda-line:nth-child(2n+1) .show-time:before {
        font-size: .6rem;
    }

    .eventCreator .content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .react-responsive-modal-modal.onTheRight {
        right: unset;
    }

    .react-responsive-modal-modal.onTheRight {
        max-width: 100vw;
    }

    .interaction .entree .buttons{
        position: relative;
        bottom: -.5rem;
    }

    .interaction .outputs .message {
        display: block;
    }

    .interaction textarea {
        width: 77vw
    }
   .interaction .teamInteraction textarea:focus-within{
       height: 40vh;
   }
   .interaction .teamInteraction:focus-within{
       position: absolute;
       background-color: white;
       left: 0%;
       z-index: 5;
   }
    .devis-bean {
        flex-direction: column;
        align-items: stretch;
        width: 98vw;
    }
    .devis-bean .details {
        overflow: auto;
        min-height: 30vh;
    }
    .devis-bean .infos{
        display: contents;
    }

    .devis-bean .tabrow {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        align-items: center;
        justify-items: center;
        background-color: var(--interv-bg-even);
    }

    .devis-bean .button-bar .flex-row {
        grid-template-columns: repeat(2, 1fr);
        row-gap: .5rem;
    }

    .devis-bean .chapter-title {
        font-size: medium;
    }
    .devis-bean .poi-chapter {
        padding: 1rem .2rem;
        margin-bottom: 1rem;
    }
    .devis-bean .poi-chapter:nth-child(2n) {
        background-color: rgba(68, 68, 68, 0.25);
    }
    .devis-bean .poi-chapter .product-list .product-line:not(:last-child):after {
        content: '';
        display: block;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        border-bottom: 1px solid black;
        width: 100%;
    }
    .devis-bean .poi-chapter .product-line .product-name {
        padding: .5rem 2rem .5rem 0;
        justify-content: start;
        background-color: var(--link);
        color: white;
        font-weight: 500;

    }
    .devis-bean .poi-chapter .product-line .product-name .as-link {
        align-self: center;
    }
    .devis-bean .poi-chapter .product-line > div {
        text-align: start;
    }
    .devis-bean .poi-chapter .product-line > div:before {
        content: attr(title);
    }

    .client-grid {
        display: flex;
        flex-direction: column;
    }

    .clientSiteList > ul {
        flex-flow: wrap;
    }

    .siteForm{
        display: flex;
        flex-direction: column;
    }

    .totalContactForm{
        width: auto;
        padding: unset;
    }

    .intervention {
        display: flex;
        flex-direction: column;
        padding-right: 0;
        width: 100vw;
        justify-content: start;
    }

    .pageTitle{
        display: none;
    }

    .page > .intervention {
        display: flex;
        padding-bottom: 5rem;
    }

    .fastMode > div > .page .intervention {
        display: flex;
    }

    .intervention .infosMobile{
        min-height: 1rem;
    }

    .intervention .risk-analysis{
        display: flex;
        flex-direction: column;
    }

    .intervention .risk-analysis .card {
        width: 60vw;
    }

    .intervention td > div:not(:first-of-type) {
        display: flex;
        flex-direction: column;
        width: 18vw;
    }

    .intervention .commentaire-cell img {
        max-height: 11vh;
    }

    .intervention .table .scrollable {
        align-items: start;
    }

    .intervention .table .scrollable .table-row .cell {
        height: 2.7rem;
        max-width: 12rem
    }

    .intervention .stepper {
        flex-wrap: wrap;
    }

    .intervention-cb .margin-content {
        grid-template: 1fr / 1fr;
    }

    .operation-table td.commentaire-cell textarea:focus-within, .operation-card .commentaire-cell textarea:focus-within {
        width: 80vw;
        height: 30vh;
        top: 10vh;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
    }

    .comments textarea {
        max-height: 40vh;
    }

    .caroussel .keep-space {
        width: 65vw;
        overflow: hidden;
    }

    .time-sheet {
        flex-direction: column;
    }

    .time-sheet > * {
        width: 90vw;
        margin: auto;
    }

    .time-sheet aside.flex-column {
        max-height: 40vh;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .time-sheet .declared {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 900;
    }

    .time-sheet .map {
        min-height: 90vh;
    }

    .extensible-component > *{
        flex-direction: column;
    }

    .supply {
        display: flex;
        flex-direction: column;
        width: 89vw;
     }
    .supply ul li .docs,  .supply ul li .machinery{
        grid-template-columns: none;
    }
    .supply ul:not(.material) {
        max-height: unset;
    }

    .supply-list {
        max-height: unset;
        height: 98%;
    }

    .supply .primary-section > .flex-row {
        flex-direction: column;
    }


    .layer > .flex-row {
        grid-template-columns: 1fr 2fr;
        justify-content: start;
        align-items: start;
    }

    .layer.read-only > .flex-row {
        display: block;
    }

    .layer .handle-point {
        flex-direction: column;
    }

    .layer canvas {
        border: 1px white solid;
        border-radius: 1rem;
        width: 100%;
    }

}

/*@media screen and (max-width: 915px) and (orientation: portrait) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        width: 100vh;
        height: 100vw;
        left: 0;
    }
}*/



/*    Animation rules   */
@keyframes prioritize {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 0;
    }
}

@keyframes prioritize-pin {
    0% {
        scale: 75%;
    }
    40% {
        scale: 130%;
    }
    60% {
        scale: 130%;
    }
    100% {
        scale: 75%;
    }
}

@keyframes waiting-for-event-pin {
    0% {
        background-color: red;
    }
    25% {
        background-color: #860101;
    }
    50% {
        background-color: red;
    }
    75% {
        background-color: #860101;
    }
    100% {
        background-color: red;
    }
}

@keyframes drop-pin {
    0% {
        /*transform: translate(0%, -1000%);*/
        transform: translateY(-1000%);
    }
    100% {
        /*transform: translate(45%, -115%);*/
        transform: translateY(-100%);
    }
}

@keyframes inflate {
    0% {
        overflow: hidden;
        width: 0;
        height: 0;
    }
    50% {
        overflow: hidden;
        width: 15rem;
        height: 0;
    }
    99% {
        overflow: hidden;
        width: 15rem;
        height: 17rem;
    }
    100% {
        overflow: hidden;
        width: unset;
        height: unset;
    }
}

@keyframes wobble {
    0% {
        transform: translateX(0%);
    }
    15% {
        transform: translateX(-25%) rotate(-5deg);
    }
    30% {
        transform: translateX(20%) rotate(3deg);
    }
    45% {
        transform: translateX(-15%) rotate(-3deg);
    }
    60% {
        transform: translateX(10%) rotate(2deg);
    }
    75% {
        transform: translateX(-5%) rotate(-1deg);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes wave {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes pulse-white {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.52);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}