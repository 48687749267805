.chatWidget:hover{
    opacity: 1;
    transition: all .5s ease-in-out;
}

.chatWidget{
    display: none;
        height: 4rem;
    flex-direction: row-reverse;
    position: fixed;
    margin-right: 1rem;
    bottom: 0;
    max-height: 22rem;
    opacity: .7;
    transition: all .5s ease-in-out;
    z-index: 25;
}


.chatContainer, .chatListContainer{
    margin-left: .5rem;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    background: var(--background-gradient);
    border-radius: 1rem;
    /*padding-right: .2rem;*/
    align-self: stretch;
    min-height: 20rem;
    max-width: 35vw;
    display: flex;
    flex-direction: column;
    transition: all ease-in-out .2s;
    z-index: 50;
}
.chatContainer.minimized, .chatListContainer.minimized{
    min-height: unset;
    align-self: end;
    cursor: pointer;
    transition: all ease-in-out 1s;
}

.chatWindowHeader, .chatListHeader:hover{
    color: black;
    transition: all ease-in-out 1s;
}
.chatWindowHeader, .chatListHeader{
    position: relative;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    padding: .5rem;
    text-align: center;
    color: rgba(59, 59, 59, 0.52);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out .5s;
    text-transform: capitalize;

}
.chatWindowHeader:not(.minimized):after, .chatListHeader:not(.minimized):after{
    content : "";
    position: absolute;
    bottom  : 0;
    left: 15%;
    height  : 1px;
    width   : 70%;
    border-bottom:1px solid #807d7d;
}
.chatListHeader.minimized:hover{
    color: black;
    width: 8rem;
    height: 2rem;
    transition: all ease-in-out 1s;
}
.chatListHeader.minimized, .chatWindowHeader.minimized{
    border-radius: 1rem;
    width: 8rem;
    transition: all ease-in-out 1s;
}
.chatWindowHeader.minimized {
    padding: unset;
    transition: all ease-in-out 1s;
}

.chatListHeader.needAttention::before, .chatWindowHeader.needAttention::before {
    content:'🔔!';
    font-size: 1.5rem;
}
.chatListHeader.needAttention, .chatWindowHeader.needAttention {
    background: rgba(208, 20, 20, 0.8);
    background-size: 200% 200%;
    animation: animateGlow 1s linear infinite;
}


.chatListHeader.needAttention::after, .chatWindowHeader.needAttention::after {
    position: absolute;
    content: "";
    z-index:-1;
    left: 0;
    right: 0;

    height: 100%;
    width: 100%;
    transform: scale(1) translateZ(0);
    filter: blur(25px);
    background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0),
            #ff0000,
            rgba(255, 255, 255, 0)
    );
    background-size: 200% 200%;
    animation: animateGlow 1s linear infinite;
}

@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 200% 50%;
    }
}

.chatListHeader, .chatWindowHeader {
    background-color: var(--nav-bar);
    color: white;
    transition: all ease-in-out .3s;

}

.fold, .close{
    width: 2rem;
    cursor: pointer;
    color: white;
}

.fold:before{
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    transform: rotate(135deg);
    width: 0.45em;
    transition: all ease-in-out .4s;
}
.minimized .fold:before{
    transform: rotate(-45deg);
}

.newMessageBtn:hover{
    box-shadow: var(--nav-bar) 0 0 15px;
    position: absolute;
    color: var(--text);

}
.newMessageBtn:active{
    transform: rotate(-45deg);
    content: 'Ouvrir une nouvelle conversation';
    bottom: 1rem;
}
.newMessageBtn{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    color: rgba(36, 36, 112, 0.87);
    background-color: rgba(255, 255, 255, 0.71);
    bottom: .7rem;
    border: 1px solid rgba(0, 0, 0, 0.45);
    right: 1rem;
    z-index: 2;
    text-align: center;
    font-weight: 900;
    font-size: 35px;
    cursor: pointer;
    transition: all ease-in-out .3s;
    user-select: none;
    padding: .2rem;
}

.chat-list{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    max-height: 30vh;
    overflow-y: scroll;
    margin-top: .5rem;
    margin-bottom: .5rem;
    width: 16rem;
    transition: all ease-in-out 1s;
}
.chat-list::-webkit-scrollbar {
    width: 8px;
}
.messageList::-webkit-scrollbar {
    width: 8px;
}
.newMessageContainer ul::-webkit-scrollbar {
    width: 8px;
}

.rce-citem{
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    text-transform: capitalize;
    font-weight: bold;
}
.rce-citem-body{
    border-bottom: unset;
}
.rce-citem-avatar{
    width: 0;
    height: 0;
    visibility: hidden;
}



.openedChat{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    transition: all ease-in-out 1s;

}


.chatWindowHeader .close:after{
    content: "\00d7";
    font-size: 28px;
    font-weight: 900;
    margin-left: .3rem;
}

.chatThread, .newMessageContainer{
    padding: .3rem;
    overflow: auto;
    display: flex;
    flex-direction: column;

    height: 100%;
    justify-content: space-between;
    backdrop-filter: blur(8px);
}
.messageList{
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    padding-right: .2rem;
    width: 20vw;
    border-radius: .3rem;
}
.divider{
    margin: .5rem auto;
    height  : 1px;
    width   : 95%;
    border-bottom:1px solid #807d7d;
}

    .message{
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 10px;


}
.message-date .message{
    min-width: fit-content;
    width: fit-content;
    max-width: 25vw;
    font-weight: 500;
    border: 1px solid rgba(56, 103, 152, 0.53);
    margin: 0 .3rem;
    padding: .5rem;
    border-radius: 1rem 1rem 1rem .1rem;
    background-color: rgba(72, 240, 255, 0.3);
}
.message-date.right .message{
    min-width: fit-content;
    max-width: 50vw;
    font-weight: 500;
    border-radius: 1rem 1rem .1rem 1rem;
    border: 1px solid rgba(38, 112, 33, 0.62);
    background-color: rgba(90, 255, 77, 0.3);
}
.message-date .date{
    margin: .5rem .3rem .2rem;
    font-size: .7rem;
    color: rgba(0, 0, 0, 0.2);
}
.message-date.right{
    align-self: end;
    text-align: right;
    margin-bottom: .3rem;
}

.newMessageContainer{
    min-width: 18rem;
}
.newMessageContainer .searchBar{
    display: flex;
    align-items: center;
    background-color: #c1ccc1;
    padding: .5rem;
    margin: .2rem;
    border-radius: .7rem;
}
.newMessageContainer .searchBar input{
    border-radius: .3rem;
    height: 1.3rem;
    border: 1px white solid;
    background-color: rgba(245, 245, 245, 0.33);
    font-family: Rubik, sans-serif;
    padding: .2rem;
    text-align: center;
}

.newMessageContainer ul{
    /*margin-bottom: 2rem;*/
    overflow: auto;
    max-height: 12rem;
    margin-block-start: 0;
    padding-inline-start: .3rem;
    padding-inline-end: .3rem;
    border: 1px solid white;
    border-radius: .3rem;
    background-color: whitesmoke;
}
.newMessageContainer ul li {
    text-transform: capitalize;
    display: block;
    list-style: none;
    border-radius: 1rem;
    border: 1px solid rgba(70, 70, 70, 0.45);
    margin: .3rem 0;
    padding: .3rem;
    cursor: pointer;
    text-align: center;
}
.newMessageContainer ul li:hover {
    background-color: rgba(255, 255, 255, 0.63);
    color: #002b46;
    transition: all ease 1s;
}

.chatInput textarea {
    min-height: 5rem;
    padding: .2rem;
    display: block;
    max-height: 5rem;
    box-sizing: initial;
    border-radius: .4rem;
    border: 1px solid rgba(0, 0, 0, 0.38);
    font-family: Rubik, sans-serif;
}

.rce-container-input {
    background-color: transparent;
}

.chatInput .rce-input-buttons .rce-button:hover{
    opacity: 1;
    transition: .3s all ease-in-out;
}

.chatInput .rce-input-buttons .rce-button{
    filter: invert(1);
    opacity: .5;
    height: 3rem;
}


@media screen and (max-width:450px) {

    .chatWidget{
        z-index: 200;
        bottom: 3vh;
        display: flex;
        flex-direction: column-reverse;
    }

.fold{
    z-index: 55;
    position: absolute;
    margin-left: 1.5rem;
}
.fold:before{
        z-index: auto;
    }

    .chatListHeader{
        font-size: 1.3rem;
    }

    .chatListContainer .minimized{
        width: 1rem;
        padding: 1rem;
        border-radius: 5rem;
        display: block;
    }

    .chatContainer{
        max-width: 100vw;
        max-height: 50vh;
        top:0;
        z-index: 10;
        box-shadow: black 0 0 10px;

    }
    .chatListHeader.minimized{
        max-width: 8rem;
        z-index: 1;
        display: block;
        margin: 0;
        width: 6rem;
    }

    .chatListHeader{
        text-align: center;
        left: 0;
    }

    .chatContainer.minimized, .chatListContainer.minimized{
        width: fit-content;
        display: block;
        position: fixed;
        margin: auto;
    }

    .chatListContainer{
        left: 0;
        display: block;
        width: fit-content;
        max-width: 72vw;
        z-index: 0;
    }

    .openedChat{
        bottom: 0vh;
        right: 2px;
        left: 2px;
        min-width: 0;
        min-height: 0;
         top: 0;
        display: block;
    }

    .chatThread, .newMessageContainer{
    width: 95vw;
        margin: auto;
    }

    .messageList{
        margin: auto;
        width: 93vw;
        background-color: rgba(255, 255, 255, 0.8);
    }

}